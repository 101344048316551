<template>
  <div class="out" ref="out">
    <div class="good_info">
      <img :src="img + '?width=200'" class="good_img" alt="" />
      <div>
        <div class="good_name">{{ name }}</div>
        <div class="good_item">{{$t('main.gwtc')}}{{ serial_number }}</div>
        <div class="good_item">{{$t('main.relT')}}{{ time }}</div>
        <div class="good_item">{{$t('main.mv')}}：￥{{ price }}</div>
      </div>
    </div>
    <div class="good_info">
      <div class="cw_title">{{$t('main.requireList')}}</div>
    </div>
    
    <div class="buy_info" v-for="(item,index) in list" :key="item.id">
      <template v-if="item.status == 1">
        <div>{{$t('main.estbid')}}{{ item.price }}</div>
        <div>{{$t('main.purchSour')}}{{ item.name }}</div>
        <div>{{$t('main.phoneNum')}}{{ item.phone }}</div>
        <div>{{$t('main.applytime')}}{{ item.created_at }}</div>
      </template>
      <template v-else>
        <div>{{$t('main.estbid')}}{{ item.price }}</div>
      <div>{{$t('main.applytime')}}{{ item.created_at }}</div>
      <div class="blur">
        <div>{{$t('main.purchSour')}}{{ item.name }}</div>
        <div>{{$t('main.phoneNum')}}{{ item.phone }}</div>
      </div>
      <div class="buy_pop">
        <img src="../assets/img/lock.png" alt="" />
        <div class="button" @click="lock_info(item)">{{$t('main.ctounlock')}}</div>
      </div>
      </template>
      <div class="to_talke" :style="item.p_user_id?'':'filter:grayscale(100%)'" @click="go_talk(item , index)">
        <div class="talk_info" v-show="item.p_has_new == 1"></div>
      </div>
    </div>

    
    <div class="buy_empty" v-show="!list.length">
        <img src="../assets/img/buy_empty.png" alt="">
        <div class="empty_text">
            {{$t('main.nappin')}}
        </div>
    </div>
    <div class="pop" v-show="lock_to" @click="change_pop">
      <div class="pay_pop_in" @click.stop>
        <div class="pay_pop_text">
          {{$t('main.unlockInfo')}}{{ once_price }}
        </div>
        <div class="button_list">
          <div @click="change_pop">{{$t('main.cancel')}}</div>
          <div @click="buy_info">{{$t('main.comfirm')}}</div>
        </div>
      </div>
    </div>
    <div class="bottom" v-show="is_next && loading1" ><van-loading size="24" color="#fff"/></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      img: "",
      name: "",
      serial_number: "",
      time: "",
      price: "",
      lock_to: false,
      once_price: 1,
      page: 1,
      list: [],
      code_list: [],
      id: "",
      is_pc: false,
      chongzhi: false,
      goods_id:'',
      collect_id:'',
      is_next:false,
      loading1:false
    };
  },
  mounted() {

    this.$refs.out.addEventListener('scroll',this.throttle(this.scrollFn,500))
    if(this.$route.query.status){
        this.goods_id = this.$route.query.goods_id
        this.collect_id = this.$route.params.id
    }else{
        this.goods_id = this.$route.params.id
        this.collect_id = 0
    }
    this.axios.get("/goods/info" + "?goods_id="+this.goods_id ).then((res)=>{
            if(this.$i18n.locale == 'en'){
              if(res.data.data.name_intl){
                res.data.data.name = res.data.data.name_intl
              }
            }
            this.name = res.data.data.name
            this.serial_number = res.data.data.serial_number
            this.img = res.data.data.pictures[0]
            this.time = res.data.data.created_at
            this.price = res.data.data.collector
            ? res.data.data.collector.price
            : res.data.data.price;
    })
    
    this.is_pc = this.IsPC();
    this.axios
      .post("collection/getCollectionApplyBuyList", {
        goods_id: this.goods_id,
        page: this.page,
        collect_id: this.collect_id,
      })
      .then((res) => {
        this.is_next = res.data.data.hasMorePages
       
        this.list = res.data.data.items
      });
  },
  methods: {
    IsPC() {
      let userAgentInfo = navigator.userAgent;
      let Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      );
      let flag = true;
      for (let v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    },
    throttle(fn, time) {
				let canRun = true;
				return function() {
			
					if (!canRun)
						return;
					canRun = false;
					setTimeout(() => {
						fn.apply(this);
						canRun = true;
					}, time)
				}
			},
    change_pop() {
      this.lock_to = !this.lock_to;
    },
    lock_info(item) {
      this.id = item.id;
      this.once_price = item.goods.level.show_buy_fee
      if(parseFloat(this.once_price) == 0){
        this.axios.post("collection/payApplyBuy", { id: this.id }).then((res) => {
          let wxpay = res.data.data;
          if(JSON.stringify(wxpay) == '{}'){
            this.page = 1
            this.axios
                    .post("collection/getCollectionApplyBuyList", {
                        goods_id: this.goods_id,
                        page: this.page,
                        collect_id: this.collect_id,
                    })
                    .then((res) => {
                      this.is_next = res.data.data.hasMorePages
                      this.$refs.out.scrollTop= 0;
                      this.list = res.data.data.items
                      
                    });
                  this.chongzhi = false;
                return
          }
        })
        return
      }
      this.lock_to = !this.lock_to;
      
    },
    scrollFn(e) {
				
				if (this.$refs.out.scrollHeight - this.$refs.out.scrollTop - this.$refs.out.clientHeight <= 20) {
					
					
						if (this.is_next) {
							this.page++
							this.loading1 = true
                            this.axios
                            .post("collection/getCollectionApplyBuyList", {
                                goods_id: this.goods_id,
                                page: this.page,
                                collect_id: this.collect_id,
                            })
                            .then((res) => {
                                this.is_next = res.data.data.hasMorePages
                                
                                let list = res.data.data.items
                                
                                this.list =  this.list.concat(list)
                                this.loading1 = false
                                
                            });
				
						}
					
					
				}
			
	},
  go_talk(item,index){
    if(!item.p_user_id){
      return
    }
    if(item.status == 0){
      weui.alert(this.$t('main.pupif'),{buttons:[{label:this.$t('main.confIrm')}]})
      return
    }else{
      this.list[index].p_has_new = 0
      this.$router.push({
        path: "/talk",
        query: {
          apply_id: item.id,
          type:1,
          goods_id:item.goods_id
        },
      });
    }


  },
    buy_info() {
      if (this.chongzhi) {
        return;
      }
      if (this.is_pc) {
        weui.alert(this.$t('main.pruwechat'),{buttons:[{label:this.$t('main.confIrm')}]});
        return;
      }
      this.chongzhi = true;
      this.axios.post("collection/payApplyBuy", { id: this.id }).then((res) => {
        if (res.data.code == 0) {

          let wxpay = res.data.data;
          
          WeixinJSBridge.invoke(
            "getBrandWCPayRequest",
            {
              appId: wxpay.appId,
              timeStamp: wxpay.timestamp,
              nonceStr: wxpay.nonceStr, // 支付签名随机串，不长于 32 位
              package: wxpay.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              signType: wxpay.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
              paySign: wxpay.paySign,
            },
            (res) => {
              if (res.err_msg == "get_brand_wcpay_request:ok") {
                weui.alert(this.$t('main.paysucc'),{buttons:[{label:this.$t('main.confIrm'),onClick:() => {
                  this.page = 1
                    
                  this.axios
                    .post("collection/getCollectionApplyBuyList", {
                        goods_id: this.goods_id,
                        page: this.page,
                        collect_id: this.collect_id,
                    })
                    .then((res) => {
                      this.is_next = res.data.data.hasMorePages
                      this.list = res.data.data.items
                      this.$refs.out.scrollTop= 0;
                      this.change_pop()
                    });
                  this.chongzhi = false;
                }}]} );
              } else {
                
                weui.alert(this.$t('main.payfail'),{buttons:[{label:this.$t('main.confIrm'),onClick:()=>{
                    this.change_pop()
                    this.chongzhi = false;
                }}]});
              }
            }
          );
        } else {
          weui.alert(res.data.msg,{buttons:[{label:this.$t('main.confIrm'),onClick:()=>{
                    this.change_pop()
                }}]});
          this.chongzhi = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.out {
  min-height: 100vh;
  height: 100vh;
  box-sizing: border-box;
  background: url(../assets/img/index_bg.png) no-repeat center;
  background-size: cover;
  background-attachment: fixed;
  padding: 0.3rem 0.275rem;
  overflow-y: auto;
}
.good_info {
  display: flex;
}
.good_img {
  width: 0.78rem;
  height: 1.12rem;
  margin-right: 0.22rem;
  object-fit: contain;
}
.cw_title {
  margin-top: 0.33rem;
  margin-left: 0.25rem;
  font-size: 0.15rem;
  position: relative;
  color: #a6210e;
  font-weight: 600;
}
.cw_title::before {
  content: "";
  display: block;
  position: absolute;
  left: -0.25rem;
  top: 0.07rem;
  background: url(../assets/img/xwt.png) no-repeat;
  background-size: cover;
  width: 0.145rem;
  height: 0.1rem;
}
.cw_title::after {
  content: "";
  display: block;
  position: absolute;
  right: -0.25rem;
  top: 0.07rem;
  background: url(../assets/img/xwt.png) no-repeat;
  background-size: cover;
  width: 0.145rem;
  height: 0.1rem;
}
.good_name {
  width: 2.2rem;
  overflow: hidden;
  font-size: 0.15rem;
  color: #000;
  font-weight: bold;
  margin-bottom: 0.16rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.good_item {
  font-size: 0.15rem;
  color: #6a6a6a;
  white-space: nowrap;
}
.buy_info {
  position: relative;
  margin-top: 0.15rem;
  width: 3.2rem;
  height: 1.23rem;
  background: url(../assets/img/price_info.png) no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding-left: 0.75rem;
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
  color: #000;
  font-size: 0.14rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.to_talke{
  width: 0.3rem;
  height: 0.3rem;
  background: url('../assets/img/to_talk.png') no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left:0.26rem;
  top:0.43rem;
}
.talk_info{
  width: 0.18rem;
  height: 0.18rem;
  background: url('../assets/img/tips.png') no-repeat;
  background-color: #fff;
  background-size: 100% 100%;
  border-radius: 50%;
  position: absolute;
  top:-0.09rem;
  left:-0.09rem;
  
}
.buy_pop {
  position: absolute;
  bottom: 0.15rem;
  height: 0.46rem;
  display: flex;
  align-items: center;
}
.buy_pop img {
  height: 0.15rem;
  object-fit: contain;
}
.buy_pop .button {
  margin-left: 0.1rem;
  background: url(../assets/img/my-zb-btn.png) no-repeat;
  background-color: #a6210e;
  border-radius: 0.05rem;
  height: 0.3rem;
  width: 0.93rem;
  background-size: 0.87rem 0.25rem;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.14rem;
  color: #fff;
}
.blur {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  filter: blur(2px);
}
.pop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  /* display: none; */
}
.pay_pop_in {
  background: url(../assets/img/to_buy_bg.png) no-repeat;
  width: 3.5rem;
  height: 1.45rem;
  background-size: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pay_pop_text {
  font-size: 0.14rem;
  color: #171717;
  margin-top: 0.33rem;
  margin-left: 0.3rem;
}
.button_list {
  width: 1.8rem;
  margin: 0 auto;
  margin-top: 0.4rem;
  display: flex;
  justify-content: space-between;
}
.button_list > div {
  width: 0.8rem;
  height: 0.33rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.15rem;
  border-radius: 0.08rem;
}
.button_list > div:nth-child(1) {
  box-shadow: 0 0 0 1px inset #9a2a25;
  color: #171717;
}
.button_list > div:nth-child(2) {
  background-color: #9a2a25;
  color: #fff;
}
.buy_empty{
    margin-top: 0.15rem;
    width: 3.2rem;
    height: 2.83rem;
    background-color: #fff;
    border-radius: 0.08rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-top: 0.4rem;
}
.buy_empty img{
    width: 1.63rem;
    height: 1.30rem;
    object-fit: contain;
}
.empty_text{
    margin-top: 0.55rem;
    color:#848484;
    font-size: 0.16rem;
}
.bottom{
  width: calc(100% - 0.6rem);
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>